<template>
  <div v-if="question">
    <div class="print:border-t print:pt-4">
      <h3
        class="text-lg font-medium leading-6 text-gray-900"
        v-if="question.name"
      >
        {{ question.name }}
      </h3>
      <p
        class="mt-1 text-sm leading-5 text-gray-500"
        v-if="subtitleHtml"
        v-html="subtitleHtml"
      ></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderView',
  props: {
    question: { type: Object },
    answer: { type: String },
    v: { type: Object },
  },
  computed: {
    subtitleHtml() {
      return this.question?.subtitle?.replace(/\n/g, '<br>') ?? '';
    },
  },
};
</script>
